import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { useIntl, Link } from '@intractive/gatsby-plugin-react-intl'
import Layout from '../../../components/Layout'
import { GatsbyImage } from "gatsby-plugin-image";

const PersonListItem = ({ person }) => (
  <li
    className="person align-center"
    itemScope
    itemType="http://schema.org/Person"
    key={person.slug}
  >
    <Link to={`/mensen/${person.slug}`}>
      {person.image ? (
        <div className={style.personImageContainer}>
          <GatsbyImage
            image={person.image.gatsbyImageData}
            className="round-image profielfoto"
            itemProp="image"
            alt={person.name} />
        </div>
      ) : (
        <div className={style.personImageContainer}>
          <img
            className="round-image profielfoto"
            src="/images/placeholder.jpg"
            alt="Placeholder"
          />
        </div>
      )}
      <div className="person-name" itemProp="name">
        {person.name}
      </div>
      {person.jobtitle && (
        <div className="person-jobTitle" itemProp="jobTitle">
          {person.jobtitle}
        </div>
      )}
    </Link>
  </li>
)

const style = require('./index.module.css')

const PageLijst = ({ data }) => {
  const page = data.pageData
  const parent = data.parentData

  const { title, metaDescription, pageTitle, text } = page

  const oudfractie = data.oudFractieData
  const intl = useIntl();

  return (
    <Layout>
      <Helmet>
        <title>{title} · STIP</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="row main-content">
        <div className="large-3 medium-3 columns sidebar hide-for-small">
          <div className="nav-sub">
            <ul>
              <li>
                <Link to={`/${parent.slug}/`} activeClassName={style.active}>
                  {parent.title}
                </Link>
                <ul>
                  {parent.subpages &&
                    parent.subpages.map(function(subpage, index) {
                      return (
                        <li key={index}>
                          <Link
                            to={`/${parent.slug}/${subpage.slug}/`}
                            activeClassName={style.active}
                          >
                            {subpage.pageTitle}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="large-9 medium-9 columns">
          <h1>{pageTitle ? pageTitle : title}</h1>

          <p>
            {intl.formatMessage({id: "Sinds de oprichting van STIP in 1993, en de eerste raadsverkiezing in 1994, hebben er al meer dan 40 STIP'ers in de Delftse gemeenteraad gezeten. Deze STIP'ers zijn hier te vinden, van jong naar oud, en met de meeste recente met een eigen profiel."})}
          </p>
          {oudfractie.oudLeden && (
            <ul className="medium-block-grid-4 small-block-grid-2 person-listing">
              {oudfractie.oudLeden.map(function(person, index) {
                return <PersonListItem person={person} key={index} />
              })}
            </ul>
          )}

          {text && (
            <div
              dangerouslySetInnerHTML={{
                __html: text.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PageLijst

export const query = graphql`
  query($node_locale: String) {
    pageData: contentfulSubpage(slug: { eq: "oud-fractie" }, node_locale: {eq: $node_locale}) {
      title
      metaDescription
      pageTitle
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    parentData: contentfulPagePersonen(node_locale: {eq: $node_locale}) {
      title
      slug
      subpages {
        slug
        title
        pageTitle
      }
    }
    oudFractieData: contentfulSubpageOudBestuurFractie(
      title: { eq: "Oud Fractieleden" },
      node_locale: {eq: $node_locale}
    ) {
      oudLeden {
        id
        name
        slug
        jobtitle
        image {
          gatsbyImageData(formats: WEBP, width: 500)
        }
      }
    }
  }
`
